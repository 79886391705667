<template>
  <c-x-features type="connect" class="Connect">
    <c-x-traits-list
      :traits="traits"
      type="connect"
    />
  </c-x-features>
</template>

<script>
import CXTraitsList from '@/components/cx/CXTraitsList'
import CXFeatures from '@/router/layouts/CXFeatures'

/**
 * @module Connect
 */
export default {
  name: 'Connect',
  components: { CXTraitsList, CXFeatures },
  data () {
    return {
      traits: [
        'discover', 'media_profiles', 'relations', 'create_pr', 'relation_groups', 'email_tracking', 'hypenews'
      ]
    }
  }
}
</script>
